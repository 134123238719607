import React, { useState, useEffect } from 'react';
import './counter.scss';

const Counter = ({ resortsCounter, showTitle = true }) => {
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        counter < resortsCounter && setTimeout(() => setCounter(counter + 1), 5 + Math.floor(counter / 25));
    }, [counter, resortsCounter]);

    return (
        <div className="counter-wrapper">
            {showTitle && "Znalezione morsowiska:"}&nbsp;<strong>{counter}</strong>
        </div>
    )
}

export default Counter
