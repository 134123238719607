import React, { useState, useEffect } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import Map from './components/map';

const App = () => {
  const [data, setData] = useState({ counter: 0 });

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(
        '/data/counter.json',
      );
      setData(await result.json());
    };

    fetchData();
  }, []);

  return (
    <>
      <Header resortsCounter={data.counter} />
      <Map />
      <Footer resortsCounter={data.counter} />
    </>
  );
}

export default App;
