import React from 'react';
import { tpay } from '../../config/tpay';
import Counter from '../couter';
import './header.scss';

const Header = ({resortsCounter}) => {
    return (
        <header className="header">
            <div className="logo-wrapper">
                <a href="https://morsowiska.pl">
                    <img src="logo.svg" className="logo" alt="Logo" width="auto" height="156" />
                </a>
                {/* <div className="counter-wrapper d-flex d-md-none">
                    <Counter resortsCounter={resortsCounter} />
                </div> */}
            </div>
            <div className="payment-wrapper">
                <p className="desc">
                    <i className="ico-fish icofont-fish-5"></i>
                    <span>Morsie, kup nam rybkę! </span>
                </p>
                <div className="amount-wrapper">
                    <a href={tpay.pln_5} className="amount" target="_blank" rel="noreferrer">
                        <span className="fee">5 zł</span>
                        {/* <span className="ico-wrapper">
                            <i className="ico icofont-fish-3"></i>
                        </span> */}
                    </a>
                    <a href={tpay.pln_10} className="amount" target="_blank" rel="noreferrer">
                        <span className="fee">10 zł</span>
                        {/* <span className="ico-wrapper">
                            <i className="ico icofont-fish-3"></i>
                            <i className="ico icofont-fish-3"></i>
                        </span> */}
                    </a>
                    <a href={tpay.pln_20} className="amount" target="_blank" rel="noreferrer">
                        <span className="fee">20 zł</span>
                        {/* <span className="ico-wrapper">
                            <i className="ico icofont-fish-3"></i>
                            <i className="ico icofont-fish-3"></i>
                            <i className="ico icofont-fish-3"></i>
                            <i className="ico icofont-fish-3"></i>
                        </span> */}
                    </a>
                </div>
            </div>
        </header>
    )
}

export default Header
