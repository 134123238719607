import React from 'react'
import { useState } from 'react';
import Toast from 'react-bootstrap/Toast'
import Button from 'react-bootstrap/Button'
import { MAIL } from '../../data/contact'
import PaymentModal from '../paymentModal/PaymentModal';
import Counter from '../couter/Counter';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import './footer.scss';

const Footer = ({ resortsCounter }) => {
    const [detailsVisible, toggleDetails] = useState(true);
    const [modalVisible, toggleModal] = useState(false);

    const handleCloseModal = () => {
        toggleModal(false);
    }

    return (
        <>
            <footer className="footer">
                <div className="hashtag-wrapper d-none d-md-block">
                    <Counter resortsCounter={resortsCounter} />
                    <a href="https://www.instagram.com/morsowiska/"
                        target="_blank"
                        rel="noreferrer">
                        <img src="morsowiska_hash.svg" className="hashtag" />
                    </a>
                </div>
                <div className="right-wrapper">
                    <div className="toast-wrapper">
                        <Toast 
                            show={detailsVisible} 
                            onClose={() => toggleDetails(false)} 
                            animation={true}
                            autohide={true}
                            delay={10000}
                            >
                            <Toast.Header>
                                <strong className="mr-auto">Brakuje morsowiska?</strong>
                            </Toast.Header>
                            <Toast.Body>
                                <p className="description">
                                    Jeśli chciałbyś dodać punkt do mapy napisz do nas na adres:&nbsp;
                                    <a href={`mailto: ${MAIL}`}>{MAIL}</a>
                                </p>
                            </Toast.Body>
                        </Toast>
                    </div>
                    <div className="btns-wrapper">
                        <span className="d-block d-md-none">
                            <OverlayTrigger
                                trigger="click"
                                key={'top'}
                                placement={'top'}
                                overlay={
                                    <Popover id={`popover-positioned-${'top'}`}>
                                        {/* <Popover.Title as="h3">{`Popover ${'top'}`}</Popover.Title> */}
                                        <Popover.Content>
                                            Ilość znalezionych morsowisk
                                    </Popover.Content>
                                    </Popover>
                                }
                            >

                                <Button className="btn btn-social" variant="primary" size="sm">
                                    {/* <i className="icosnow icofont-snow-flake" /> */}
                                    
                                    <img src="indicator.svg" className="indicator" />
                                    <Counter resortsCounter={resortsCounter} showTitle={false} />
                                </Button>
                            </OverlayTrigger>
                        </span>

                        <Button className="btn btn-social" variant="primary" size="sm" onClick={() => window.open('https://facebook.com/morsowiska')}>
                            <i className="icofont-facebook" />
                        </Button>
                        {/* <Button className="btn btn-social" variant="info" size="sm" onClick={() => window.open('https://instagram.com/morsowiska')}>
                            <i className="icofont-instagram" />
                        </Button> */}
                        <Button className="btn btn-social" variant="primary" size="sm" onClick={() => toggleModal(!modalVisible)}>
                            <i className="icofont-gift" />
                        </Button>
                        <Button className={`btn btn-contact ${detailsVisible ? 'active' : ''}`} variant="primary" size="sm" onClick={() => toggleDetails(!detailsVisible)}>
                            <i className={`icofont-ui-${detailsVisible ? 'close' : 'add'}`}></i>
                        </Button>
                    </div>
                </div>
            </footer>
            <PaymentModal isVisible={modalVisible} onClose={handleCloseModal} />
        </>
    )
}

export default Footer;
