import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { tpay } from '../../config/tpay';

const PaymentModal = ({ isVisible, onClose }) => {
    const [show, setShow] = useState(isVisible);

    useEffect(() => {
        setShow(isVisible)
    }, [isVisible]);

    const handleClose = () => {
        setShow(false);
        onClose();
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Morsie!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Jeżeli podoba Ci się idea mapy dla morsów, wesprzyj nas!</p>
                    <p>Serwis powstał na użytek nas wszystkich. Działamy non-profit, dlatego każda zebrana złotówka pozwoli nam utrzymać serwis na najwyższym poziomie.</p>
                    <p>Po wybraniu kwoty zostaniesz przekierowany do szybkiej i bezpiecznej płatności obsługiwanej przez operatora płatności online <a href="https://tpay.com/" target="_blank" rel="noreferrer">tpay.com</a></p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row amount-wrapper">
                        <a href={tpay.pln_5} className="amount" target="_blank" rel="noreferrer">5 zł</a>
                        <a href={tpay.pln_10} className="amount" target="_blank" rel="noreferrer">10 zł</a>
                        <a href={tpay.pln_20} className="amount" target="_blank" rel="noreferrer">20 zł</a>
                        <a href={tpay.pln_50} className="amount" target="_blank" rel="noreferrer">50 zł</a>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PaymentModal;

