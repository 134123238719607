import React, { useState } from 'react'
import Spinner from '../spinner/Spinner';
import './map.scss';

const mapUrl = "https://www.google.com/maps/d/embed?mid=1olWLJpvY3zEoISDwMbMnHkTeDG5rfCaB";

const Map = () => {
    const [mapSrc, setMapSrc] = useState();
    const [isGeoLocation, setGeoLocation] = useState(false);

    const onGeoConfirm = (pos) => {
        const coords = pos.coords;
        const mapSrcGeo = `${mapUrl}&z=11&ll=${coords.latitude},${coords.longitude}`;
        setMapSrcTimeout(mapSrcGeo);
    };

    const onGeoReject = () => {
        setMapSrcTimeout(mapUrl);
    };

    const setMapSrcTimeout = (mapSrc) => setTimeout(() => setMapSrc(mapSrc), 1000);

    const loadGeolocation = () => {
        setGeoLocation(true);
        navigator.geolocation.getCurrentPosition(onGeoConfirm, onGeoReject);
    }

    !isGeoLocation && loadGeolocation();

    return (
        <section className="map-wrapper">
            {mapSrc
                ? <iframe id="map" name="map" title="map" src={mapSrc} className="map" frameBorder="0" />
                : <Spinner />
            }
        </section>
    )
}

export default Map;
